

export class printer_payload
{
    constructor() {
        this.paperWidth = 80;
        this.name = null;
        this.phoneNumber = null;
        this.email = null;
        this.address = null;
        this.taxInfo = null;
        this.customerInfo = { 
            name : null,
            address : null,
            email : null,
            phoneNumber : null,
            taxInfo : null,
            postalCode: null,
            municipality: null,
            suburb: null,
            state: null,
            country: null
        };
        this.products = [];
        this.id = null;
        this.date = null;
        this.subtotal = null;
        this.taxes = null;
        this.total = null;
        this.shipping = null;
        this.payments = [];
        this.cashier = {
            cash : null,
            cash_20 : null,
            cash_50 : null,
            cash_100 : null,
            cash_200 : null,
            cash_500 : null,
            cash_1000 : null,
            others : null,
            total : null,
            description : null,
            user : null,
            date : null,
            movement : null
        };
        this.footer = null;
        this.openCashDrawer = null;
        this.change = 0.00;
    }
}