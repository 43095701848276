<template>
  <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4">
        <!-- <div class="card">
            <div class="card-header" style="text-align: center;">{{product.name}}</div>
        </div> -->
        <Card @click="select" class="product">
            <template #header>
                <img alt="user header" style="height: 9rem" :src="image">
            </template>
            <template #content>
                <div style="text-align: center;">
                    <strong>{{product.name}}</strong>
                    <p>{{formatCurrency(product.price)}}</p>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            default: () => {}
        },
    },
    computed: {
        image() {
            return this.product.image == null ? 'https://comnplayscience.eu/app/images/notfound.png'
             : this.$config.api_route + "images/products/" + this.product.image;
        }
    },
    methods: {
        select() {
            this.$emit('selected', this.product)
        },
        formatCurrency(value) {
			return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
		}
    }
}
</script>

<style >
    .product {
        cursor: pointer;
        min-height: 12.5rem;
    }
    .product:hover {
        background-color: rgb(50, 85, 160);
    }
</style>