<template>
    <Dialog
        v-model:visible="localValue"
        :breakpoints="{ '960px': '30vw', '640px': '50vw' }" 
        :style="{ width: '50vw' }"         
        :modal="true" 
        :closable="true"
      >
      <template #header>
        <h4><i class="pi pi-print mr-3" style="font-size: 2rem;" />Imprimir</h4>
      </template>
      <div class="formgrid grid p-fluid">
        <div style="padding-bottom:1rem;" class="col-12" v-for="printer in printers" :key="printer.id">
          <Button :disabled="loading" :loading="loading" @click="print(printer)" :label="printer.name + ' - ' +  printer.printer_config" icon="pi pi-print" />
        </div>
      </div>
    </Dialog>
  </template>
  
  <script>
  import axios from 'axios';
  import formMixin from '../mixins/form';
  
  export default {
    mixins: [formMixin],
    expose: ['print'],
    props: {
      modelValue: {
        required: true,
      },
      printers: {
        type: Array,
        default: () => [] 
      },
      payload: {
        type: Object,
        default: () => {}
      },
      type: {
        type: String,
        default: "test"
        /* 
          test
          payment
          payment/reprint
          preparation
          cashier/report
          cashier/open
        */
      },
      url: {
        type: String,
        default: 'https://localhost:7215/print/'
      }
    },
    data() {
      return {
        loading: false,
        localValue: this.modelValue,
      };
    },
    methods: {
      async print(printer, extPayload, url, type) {
        try {
          this.loading = true;
          let payload = null;
          payload = extPayload ?? { ...this.payload };
          payload.port = printer.printer_config;
          await axios.post((url ?? this.url) + (type ?? this.type), payload); 
          this.localValue = false;
        } catch (error) {
          this.localValue = true;
          this.showError(error);
        } finally { 
          this.loading = false;
        }
      }
    },
    watch: {
      localValue(newValue) {
        this.$emit('update:modelValue', newValue);
      },
      modelValue(newValue) {
        this.localValue = newValue;
      },
    },
  };
  </script>
  
  <style></style>
  