<template>
  <Dialog v-model:visible="localValue" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }"
    :modal="true" :closable="true" :close-on-escape="false">
    <template #header>
      <h4>{{title}}</h4>
    </template>
    <div class="confirmation-content">
    
      <FadeInOut entry="left" exit="right" :duration="350" mode="out-in">
        <div class="p-fluid grid formgrid" v-if="!is_valid">
        <div class="col-12">
          <Fieldset legend="Informacion">
            <div class="p-fluid grid formgrid">
              <FormCalendar :showTime="true" :disabled="true" v-model="ticketDate" :wrapperClass="'col-6 field'"
                :label="'Fecha'"></FormCalendar>
              <FormInputText :readonly="true" v-model="internalTicket.id_number" :wrapperClass="'col-6 field'"
                :label="'#'"></FormInputText>
              <FormInputNumber :readonly="true" :mode="'currency'" v-model="internalTicket.subtotal"
                :wrapperClass="'col-4 field'" :label="'Subtotal'"></FormInputNumber>
              <FormInputNumber :readonly="true" :mode="'currency'" v-model="internalTicket.iva"
                :wrapperClass="'col-4 field'" :label="'IVA'"></FormInputNumber>
              <FormInputNumber :readonly="true" :mode="'currency'" v-model="internalTicket.total"
                :wrapperClass="'col-4 field'" :label="'Total'"></FormInputNumber>
            </div>
          </Fieldset>
          <br>
        </div>
        <div class="col-12">
          <Fieldset legend="Validacion">
            <div class="p-fluid grid formgrid">
              <FormInputText v-model="username" wrapperClass="col-6 field" label="Usuario"></FormInputText>
              <FormInputText v-model="password" wrapperClass="col-6 field" label="Password" :type="'password'"></FormInputText>
            </div>
          </Fieldset>
        </div>
      </div>
      <div class="p-fluid grif formgrid" v-auto-animate v-else-if="is_valid && event == 'cancelation'">
        <div class="col-12">
          <Fieldset legend="Informacion">
            <div class="p-fluid grid formgrid">
              <FormInputText :textArea="true" v-model="this.internalTicket.cancel_comment" :wrapperClass="'col-12 field'"
                :label="'Comentario'"></FormInputText>
            </div>
          </Fieldset>
          <br>
        </div>
      </div>
      </FadeInOut>
    </div>
    <template #footer>
      <Button v-if="!is_valid" label="Aceptar" icon="pi pi-check" :loading="loading" @click="validate" />
      <Button v-else label="Confirmar" icon="pi pi-check" :loading="loading" @click="confirm" />
    </template>
  </Dialog>
</template>
  
<script>

import FormInputText from "../components/general/FormInputText.vue";
import FormInputNumber from "../components/general/FormInputNumber.vue";
import FormCalendar from "./general/FormCalendar.vue";
import { ticket } from "../models/ticket";
import { User } from "../models/user";
import formMixin from "../mixins/form";
import { fillObject } from '../utilities/General';
export default {
  mixins: [formMixin],
  props: {
    title: null,
    event: null,
    ticket: {
      type: Object,
      default: () => { }
    },
    modelValue: {
      required: true,
    }
  },
  data() {
    return {
      comment: null,
      is_valid: false,
      loading: false,
      username: null,
      password: null,
      internalTicket: this.ticket,
      localValue: this.modelValue,
    };
  },
  components: {
    FormInputText, FormInputNumber, FormCalendar
  },
  computed: {
    ticketDate() {
      return new Date(this.internalTicket.date);
    }
  },
  watch: {
    localValue(newValue) {
      this.$emit('update:modelValue', newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
    ticket(newValue) {
      this.internalTicket = newValue;
    }
  },
  methods: {
    async confirm() {
      try {
        this.loading = true;
        let cancel_ticket = new ticket(this.session);
        cancel_ticket = fillObject(cancel_ticket, this.internalTicket);
        cancel_ticket.cancel_user = this.username;
        await cancel_ticket.cancel();
        this.is_valid = false;
        this.username = null;
        this.password = null;
        this.localValue = false;
      } catch (error) {
        this.showError(error);
        this.is_valid = true;
      } finally {
        this.loading = false;
      }
    },
    async validate() {
      try {
        this.loading = true;
        await new User().validate(this.event, this.username, this.password);
        this.is_valid = true;
      } catch (error) {
        this.showError(error);
        this.is_valid = false;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
  
<style>

</style>
  