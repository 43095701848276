<template>
  <div class="grid formgrid p-fluid">
        <div class="col-12">
          <Loader v-model="loading" />
          <Panel header="Cliente">
                <div class="formgrid grid p-fluid">
                    <div class="col-12">
                        <FormToolbar :actions="modal ? ['new','save'] : ['new','save','delete']" @new="newEntity" @save="saveEntity" @delete="deleteEntity" />
                    </div>
                    <FormInputText v-text-style:upper="entity.name" :valid="validate.validations.name"  wrapperClass="field col-12 xl:col-8" label="Nombre" v-model="entity.name" />
                    <FormInputText v-text-style:upper="entity.rfc" wrapperClass="field col-12 xl:col-4" label="RFC" v-model="entity.rfc" />
                    <FormInputText wrapperClass="field col-12 xl:col-4" label="Pais" v-model="entity.country" />
                    <Autocomplete :items="estados" wrapperClass="field col-12 xl:col-4" label="Estado" v-model="entity.state" />
                    <Autocomplete :items="municipios" wrapperClass="field col-12 xl:col-4" label="Municipio" v-model="entity.municipality" />
                    <FormInputText :tooltip="'Presione ↩ ENTER para buscar la informacion por código postal'" @enter="searchZIP" wrapperClass="field col-12 xl:col-4" label="C.P." v-model="entity.postal_code" />
                    <Autocomplete :items="colonias" wrapperClass="field col-12 xl:col-8" label="Colonia" v-model="entity.suburb" />
                    <FormInputText wrapperClass="field col-12 xl:col-12" label="Direccion" v-model="entity.address" />
                    <FormInputText :valid="validate.validations.email" wrapperClass="field col-12 xl:col-4" label="E-mail" v-model="entity.email" />
                    <FormInputText wrapperClass="field col-12 xl:col-4" label="Telefono" v-model="entity.phone" />
                </div>                   
            </Panel>
        </div>
        <div v-if="!modal" class="col-12">
          <div class="card">
              <BasicDatatable :rows="entities" :selectionMode="'single'" :headers="headers" @selected="selectEntity"/>
          </div>
        </div>
  </div>
</template>
<script>
import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import Loader from "../../../components/general/Loader.vue";
import formMixin from "../../../mixins/form";
import Autocomplete from "../../../components/general/Autocomplete.vue";

import { customer } from "../../../models/customer";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { fillObject, HeaderGrid, Rule, satCatalogo, validate } from '../../../utilities/General';
export default {
  mixins: [formMixin],
  expose: ['refresh'],
  components: { Autocomplete, Loader, FormInputText, FormToolbar, BasicDatatable },
  props: {
    modal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      c_Estado: [],
      c_Municipio: [],
      c_Colonias: [],
      validate: {
          valid: false,
          validations: {
              name: null,
              email: null
          }
      },
      rules: [
          new Rule({ name: "name" }),
          new Rule({ name: "email"})
      ],
      entity: new customer(),
      entities: [],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("E-mail", "email"),
        new HeaderGrid("Telefono", "phone"),
      ]
    };
  },
  methods: {
    async searchZIP() {
      this.loading = true;
      try {
        let zip_code = await this.entity.zip(this.entity.postal_code);
        this.entity.state = this.c_Estado.find(x => x.c_Estado == zip_code.c_Estado).Nombre;
        this.entity.municipality = this.c_Municipio.find(x => x.c_Municipio == zip_code.c_Municipio 
        && x.c_Estado == zip_code.c_Estado).Descripcion;
        this.c_Colonias = zip_code.colonias;
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
        this.loading = true;
        this.entity = new customer(this.session);
        try {
            this.entities = await this.entity.all();
        } catch (error) {
            this.showError(error);
        } finally {
            this.loading = false;
        }
    },
    deleteEntity(entity) { 
      console.log(entity);
    },
    selectEntity(entity) {
      this.entity = fillObject(this.entity, entity);
    },
    newEntity() {
      this.entity = new customer(this.session);
    },
    async saveEntity() {
      this.loading = true;
      try {
        this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
            throw "Verificar campos requeridos"
        }
        var newEntity = await this.entity.save();
        this.entities.push(newEntity);
        this.newEntity();
        this.showSuccess("Informacion guardada con exito");
        this.$emit("save", newEntity);
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    estados() {
      return this.c_Estado.map(x => x.Nombre);
    },
    municipios() {
      let estado = this.c_Estado.find(y => y.Nombre == this.entity.state);
      if (!estado)
        return [];
      return this.c_Municipio
                          .filter(x => x.c_Estado == estado.c_Estado)
                          .map(x => x.Descripcion);
    },
    colonias() {
      return this.c_Colonias.map(x => x.nombre);
    }
  },
  async created() {
    this.c_Estado = (await satCatalogo(4)).filter(x => x.c_Pais == "MEX");
    this.c_Municipio = await satCatalogo(10);
  },
  mounted() {
      this.entity = new customer(this.session);
  }
};
</script>

<style>
</style>

