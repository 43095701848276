<template>
    <Dialog
        v-model:visible="localValue"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }" 
        :style="{ width: '50vw' }"         
        :modal="true" 
        :closable="true"
      >
      <template #header>
        <h4>
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem;" />
        Confirmar</h4>
      </template>
      <div class="confirmation-content">
        <p style="text-align: center;">{{message}}</p>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="$emit('closed')"
        />
        <Button
          label="Si"
          icon="pi pi-check"
          class="p-button-text"
          @click="$emit('deleted', false)"
        />
      </template>
    </Dialog>
  </template>
  
  <script>
  export default {
    props: {
      modelValue: {
        required: true,
      },
      message: {
        type: String,
        default: '¿Estas seguro de eliminar el registro?'
      }
    },
    data() {
      return {
        localValue: this.modelValue,
      };
    },
    watch: {
      localValue(newValue) {
        this.$emit('update:modelValue', newValue);
      },
      modelValue(newValue) {
        this.localValue = newValue;
      },
    },
  };
  </script>
  
  <style></style>
  