<template>
    <div :class="wrapperClass">
        <label v-if="label">{{label}}</label>
        <Dropdown 
            @change="onChange"
            @beforeShow="onBeforeShow"
            :filter="true" 
            :showClear="true"
            v-model="localValue" 
            :options="options" 
            :optionLabel="optionLabel" 
            :optionValue="optionValue" 
            :readonly="readonly"
            :disabled="disabled"
            :filterFields="filterFields"
            :class="{ 'p-invalid': valid === false }">
            <template #value="slotProps">
                <div v-if="slotProps.value && hasValueSlot()">
                  <!-- {{options.find(x => slotProps.value ) }} -->
                  <slot name="value" :value="slotProps.value" />
                </div>
                <div v-else-if="slotProps.value">
                    <div>{{getValue(slotProps.value)}}</div>
                </div>
                <div v-else>
                    <div>{{placeholder}}</div>
                </div>
            </template>
            <template #option="slotProps">
                <div v-if="hasOptionSlot()">
                  <slot name="option" :option="slotProps.option" />
                </div>
                <div v-else>
                    <div>{{getLabel(slotProps.option)}}</div>
                </div>
            </template>
        </Dropdown>
        <small class="p-invalid" v-if="valid === false"> {{validation}} </small>
    </div>
</template>

<script>
export default {
  props: {
    showClear: {
      type: [Boolean, null],
      default: null
    },
    filterFields: {
      type: [Array, null],
      default: null
    },
     disabled: {
      type: [Boolean, null],
      default: null
    },
    readonly: {
      type: [Boolean, null],
      default: null
    },
    placeholder: {
      type: String,
      default: "..."
    },
    modelValue: {
      type: [String, Number, null],
      required: true,
    },
    options: {
      type: Array,
      default: () => []
    },
    optionLabel: {
        type: String,
        default: null
    },
    optionValue: {
        type: String,
        default: null
    },
    wrapperClass: {
        type: String,
        default: "field"
    },
    label: {
        type: String,
        default: null
    },
    valid: {
        type: Boolean,
        //Todos son validos antes del submit
        default: true
    },
    validation: {
        type: String,
        default: "Favor de llenar el campo"
    },
    labelInOption: {
        type: String,
        default: ""
    },
    labelInValue: {
        type: String,
        default: ""
    }
  },
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  methods: {
    hasValueSlot() {
      return !!this.$slots.value;
    },
    hasOptionSlot() {
      return !!this.$slots.option;
    },
    onBeforeShow(event) {
      this.$emit('beforeShow', event)
    },
    onChange(event){
      this.$emit('update:modelValue', event.value);
      this.$emit('change', event.value);
    },
    getLabel(val, ver) { 
      let label = (ver ?? 1) == 1  ? this.labelInOption : this.labelInValue;
      Object.keys(val).forEach(key => {
        label = label.replace(key, val[key]);
      });
      return label;
    },
    getValue(val) { 
      if (val) {
        let myVal = this.options.find(x => x[this.optionValue] == val);
        if (myVal) 
          return this.getLabel(myVal, 2);
        else 
          return "";
      }else 
      return "";
    }
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>
<style>
</style>