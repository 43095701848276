<template>
  <div class="grid formgrid p-fluid">
        <div class="col-12">
          <Loader v-model="loading" />
        <div class="card">
            <Panel header="Datos Fiscales">
                <div class="formgrid grid p-fluid">
                    <div class="col-12">
                        <FormToolbar :actions="modal ? ['save'] : ['new','save','delete']" @new="newEntity" @save="saveEntity" @delete="deleteEntity" />
                    </div>
                    <FormInputText v-text-style:upper="entity.name"  :valid="validate.validations.name"  wrapperClass="field col-12 xl:col-8" label="Nombre" v-model="entity.name" />
                    <FormInputText v-text-style:upper="entity.rfc"  :validation="'Validar que RFC tenga 12 o 13 caracteres'" :valid="validate.validations.rfc" wrapperClass="field col-12 xl:col-4" label="RFC" v-model="entity.rfc" />
                    <FormDropdown @change="countryChanged" :filter="true" wrapperClass="field col-12 xl:col-4" label="Pais" v-model="entity.country" :options="c_Pais"  :optionLabel="'Descripcion'" :optionValue="'Descripcion'" />
                    <FormDropdown @change="stateChanged" v-if="entity.country == 'México'" wrapperClass="field col-12 xl:col-4" label="Estado" v-model="entity.state" :options="c_EstadoPais" :filter="true" :optionLabel="'Nombre'"  :optionValue="'Nombre'"/>
                    <FormInputText v-else wrapperClass="field col-12 xl:col-4" label="Estado" v-model="entity.state" />
                    <FormDropdown  v-if="entity.country == 'México'" wrapperClass="field col-12 xl:col-4" label="Municipio" v-model="entity.municipality" :options="c_MunicipioEstado" :filter="true" :optionLabel="'Descripcion'"  :optionValue="'Descripcion'"/>
                    <FormInputText v-else wrapperClass="field col-12 xl:col-4" label="Municipio" v-model="entity.municipality" />
                    <FormInputText wrapperClass="field col-12 xl:col-4" label="Direccion" v-model="entity.address" />
                    <FormInputText wrapperClass="field col-12 xl:col-4" label="C.P." v-model="entity.postal_code" />
                    <FormInputText wrapperClass="field col-12 xl:col-4" label="Colonia" v-model="entity.suburb" />
                    <FormInputText :validation="'Favor de ingresar un correo valido ***@dominio.com '" :valid="validate.validations.email" wrapperClass="field col-12 xl:col-4" label="E-mail" v-model="entity.email" />
                    <FormInputText wrapperClass="field col-12 xl:col-4" label="Telefono" v-model="entity.phone" />
                    <FormDropdownComplex :valid="validate.validations.tax_regime" :labelInOption="'c_RegimenFiscal - Descripcion'" :labelInValue="'c_RegimenFiscal - Descripcion'" wrapperClass="field col-12 xl:col-4" label="Regimen Fiscal" v-model="entity.tax_regime" :options="c_RegimenFiscal" :optionLabel="'Descripcion'" :optionValue="'c_RegimenFiscal'" />
                    <FormDropdownComplex :valid="validate.validations.use_cfdi" :labelInOption="'c_UsoCFDI - Descripcion'" :labelInValue="'c_UsoCFDI - Descripcion'" wrapperClass="field col-12 xl:col-4" label="Uso CFDI" v-model="entity.use_cfdi" :options="c_UsoCFDI" :optionLabel="'Descripcion'" :optionValue="'c_UsoCFDI'" />
                </div>                   
            </Panel>
        </div>
        </div>
        <div v-if="!modal" class="col-12">
          <div class="card">
              <BasicDatatable :rows="entities" :selectionMode="'single'" :headers="headers" @selected="selectEntity"/>
          </div>
        </div>
  </div>
</template>
<script>
import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";
import FormDropdown from "../../../components/general/FormDropdown.vue";

import Loader from "../../../components/general/Loader.vue";
import formMixin from "../../../mixins/form";

import { customer } from "../../../models/customer";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { fillObject, HeaderGrid, Rule, satCatalogo, validate } from '../../../utilities/General';
export default {
  mixins: [formMixin],
  expose: ['refresh'],
  components: { Loader, FormDropdownComplex, FormDropdown, FormInputText, FormToolbar, BasicDatatable },
  props: {
    id_customer: null,
    modal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    c_EstadoPais() {
      return this.c_Estado.filter(x => x.c_Pais == "MEX");
    },
    c_MunicipioEstado() {
      if (!this.entity.state) {
        return [];
      }
      let estado = this.c_Estado.find(x => x.Nombre == this.entity.state); 
      return this.c_Municipio.filter(x => x.c_Estado == estado.c_Estado);
    }
  },
  data() {
    return {
      loading: false,
      validate: {
            valid: false,
            validations: {
                name: null,
                email: null,
                tax_regime: null,
                use_cfdi: null,
                rfc: null
            }
        },
        rules: [
            new Rule({ name: "name" }),
            new Rule({ name: "email", type: "email"}),
            new Rule({ name: "tax_regime"}),
            new Rule({ name: "use_cfdi"}),
            new Rule({ name: "rfc", type: "rfc"})
        ],
      entity: new customer(),
      entities: [],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("E-mail", "email"),
        new HeaderGrid("Telefono", "phone"),
      ],
      c_RegimenFiscal: [],
      c_UsoCFDI: [],
      c_Estado:  [],
      c_Pais: [],
      c_Municipio: []
    };
  },
  methods: {
    async refresh() {
        this.loading = true;
        this.entity = new customer(this.session);
        try {
            this.entities = await this.entity.all();
        } catch (error) {
            this.showError(error);
        } finally {
            this.loading = false;
        }
    },
    countryChanged() {
      this.entity.municipality = null;
      this.entity.state = null;
    },
    stateChanged() {
      this.entity.municipality = null;
    },
    deleteEntity(entity) { 
      console.log(entity);
    },
    selectEntity(entity) {
      this.entity = fillObject(this.entity, entity);
    },
    newEntity() {
      this.entity = new customer(this.session);
    },
    async saveEntity() {
      this.loading = true;
      try {
        this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
            throw "Verificar campos requeridos"
        }
        if (this.entity.id) {
          var updatedEntity = await this.entity.update();
          this.entities[this.entities.findIndex(x => x.id == this.entity.id)] = updatedEntity;
        }else {
          var newEntity = await this.entity.save();
          this.entities.push(newEntity);
        }
        this.newEntity();
        this.showSuccess("Informacion guardada con exito");
        this.$emit("save", newEntity);
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    try {
      this.c_RegimenFiscal = await satCatalogo(2);
      this.c_UsoCFDI = await satCatalogo(15);
      this.c_Pais = await satCatalogo(11);
      this.c_Estado = await satCatalogo(4);
      this.c_Municipio = await satCatalogo(10);
      this.entity = new customer(this.session);
      this.entity.id = this.id_customer;
      if (this.entity.id) {
        this.loading = true;
        this.entity = fillObject(this.entity, await this.entity.retrieve());
      }
    } catch (error) {
      alert(error)
    } finally { 
      this.loading = false;
    }
  }
};
</script>

<style>
</style>

