<template>
    <Dialog
        v-model:visible="localValue"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }" 
        :style="{ width: '65vw' }"         
        :modal="true" 
        :closable="true"
      >
      <template #header>
        <h4>
        <i class="pi pi-car mr-3" style="font-size: 2rem;" />
        Información de Entrega</h4>
      </template>
      <div class="confirmation-content">
        <div class="grid formgrid p-fluid">
          <div class="col-12">
            <p>La información sera cargada apartir del cliente seleccionado, si no existe el cliente se creara uno nuevo con esta información</p>
          </div>
          <div class="col-12">
                        <FormToolbar :actions="['new','save']" @new="newEntity" @save="saveEntity" @delete="deleteEntity" />
          </div>
          <FormInputText v-text-style:upper="entity.name" :valid="validate.validations.name"  wrapperClass="field col-12 xl:col-12" label="Nombre" v-model="entity.name" />
          <FormInputText wrapperClass="field col-12 xl:col-4" label="Pais" v-model="entity.country" />
          <Autocomplete :items="estados" wrapperClass="field col-12 xl:col-4" label="Estado" v-model="entity.state" />
          <Autocomplete :items="municipios" wrapperClass="field col-12 xl:col-4" label="Municipio" v-model="entity.municipality" />
          <FormInputText :tooltip="'Presione ↩ ENTER para buscar la informacion por código postal'" @enter="searchZIP" wrapperClass="field col-12 xl:col-4" label="C.P." v-model="entity.postal_code" />
          <Autocomplete :items="colonias" wrapperClass="field col-12 xl:col-8" label="Colonia" v-model="entity.suburb" />
          <FormInputText wrapperClass="field col-12 xl:col-8" label="Direccion" v-model="entity.address" />
          <FormInputText wrapperClass="field col-12 xl:col-4" label="Comentarios" v-model="entity.comment" />
          <FormInputText :valid="validate.validations.email" wrapperClass="field col-12 xl:col-4" label="E-mail" v-model="entity.email" />
          <FormInputText wrapperClass="field col-12 xl:col-4" label="Telefono" v-model="entity.phone" />
        </div>
      </div>
    </Dialog>
  </template>
  
  <script>

import { customer } from "../models/customer";
import FormToolbar from "./general/BasicFormToolbar.vue";

import FormInputText from "./general/FormInputText.vue";
import formMixin from "../mixins/form";
import Autocomplete from "./general/Autocomplete.vue";
import { fillObject, Rule, satCatalogo, validate } from '../utilities/General';

  export default {
    expose: ['clear'],
    mixins: [formMixin],
    props: {
      modelValue: {
        required: true,
      },
      actualCustomer: {
        required: true
      }
    },
    components: { FormInputText, Autocomplete, FormToolbar},
    data() {
      return {
        localValue: this.modelValue,
        entity: new customer(),
        c_Estado: [],
        c_Municipio: [],
        c_Colonias: [],
        validate: {
            valid: false,
            validations: {
                name: null,
                email: null
            }
        },
        rules: [
            new Rule({ name: "name" }),
            new Rule({ name: "email"})
        ],
      };
    },
    computed: {
      estados() {
        return this.c_Estado.map(x => x.Nombre);
      },
      municipios() {
        let estado = this.c_Estado.find(y => y.Nombre == this.entity.state);
        if (!estado)
          return [];
        return this.c_Municipio
                            .filter(x => x.c_Estado == estado.c_Estado)
                            .map(x => x.Descripcion);
      },
      colonias() {
        return this.c_Colonias.map(x => x.nombre);
      }
    },
    methods: {
      clear() {
        this.entity = new customer(this.session);
      },
      newEntity() {
        alert('asdkoasd')
      },
      async saveEntity() {
          this.loading = true;
          try {
            this.validate = validate(this.entity, this.rules);
            if (!this.validate.valid) {
                throw "Verificar campos requeridos"
            }
            var newEntity = await this.entity.save();
            this.showSuccess("Informacion guardada con exito");
            this.$emit("save", newEntity);
          } catch (error) {
            this.showError(error);
          } finally {
            this.loading = false;
          }
      },
      async searchZIP() {
        this.loading = true;
        try {
          let zip_code = await this.entity.zip(this.entity.postal_code);
          this.entity.state = this.c_Estado.find(x => x.c_Estado == zip_code.c_Estado).Nombre;
          this.entity.municipality = this.c_Municipio.find(x => x.c_Municipio == zip_code.c_Municipio 
          && x.c_Estado == zip_code.c_Estado).Descripcion;
          this.c_Colonias = zip_code.colonias;
        } catch (error) {
          this.showError(error);
        } finally {
          this.loading = false;
        }
      },
    },
    watch: {
      actualCustomer(newValue) {
        this.entity = fillObject(this.entity, newValue);
      },
      localValue(newValue) {
        this.$emit('update:modelValue', newValue);
      },
      modelValue(newValue) {
        this.localValue = newValue;
      },
    },
    async created() {
      this.c_Estado = (await satCatalogo(4)).filter(x => x.c_Pais == "MEX");
      this.c_Municipio = await satCatalogo(10);
      this.entity.state = "Nuevo León";
    },
  };
  </script>
  
  <style>
  *::-webkit-scrollbar {
    width: 5px;
  }

/* Track */
  *::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
  }

/* Handle */
  *::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
  }

/* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: #555;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  
  *::-webkit-scrollbar-thumb:active {
    background-color: #999999;
  }
</style>
  