<template>
  <Sidebar class="p-sidebar-md" @hide="onHide" v-model:visible="localValue" position="right"> 
      <h5 v-if="ticket.id">Pago de ticket #{{ticket.order_number}}</h5> 
      <h5 v-else>Pago de ticket</h5>

      <OverlayPanel ref="payment">
        <div class="p-inputgroup">
          <InputNumber ref="paymentAmount" :max="selectedPayment.name == 'EFECTIVO' ? null : totalAmount" :min="1" v-if="selectedPayment" v-model="selectedPayment.amount" mode="currency" currency="USD" locale="en-US" />
          <Button @click="deletePayment" icon="pi pi-times"  class="p-button-danger"/>     
        </div>
      </OverlayPanel>
      <Dialog header="Informacion para facturacion" v-model:visible="modalInvoice" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '80vw'}" :modal="true">
        <InformacionFacturacion :id_customer="ticket.id_customer" @save="saveInoviceInfo" :modal="true" />
      </Dialog>

      <br>
      <div class="grid">
          <div class="col-12">
              <div class="card payment-card">
                  <div class="grid">
                    <div class="col-6">Total a pagar: </div>
                    <div class="col-6" v-if="ticket.id_service_type != 3"> {{formatCurrency(totalAmount)}}</div>
                    <div class="col-6" v-else> {{formatCurrency(totalAmount - envio)}}</div>
                    <div class="col-6" v-if="ticket.id_service_type == 3">Envio: </div>
                    <div class="col-6" v-if="ticket.id_service_type == 3"> [{{formatCurrency(envio)}}] - {{ tiempoEntrega }} minutos</div>
                    <div class="col-6">Pagado: </div>
                    <div class="col-6"> {{formatCurrency(paidAmount)}}</div>
                    <div class="col-6">Cambio: </div>
                    <div class="col-6"> {{formatCurrency(leftoverCash)}}</div>
                    
                  </div>
              </div>
          </div>
          <div class="col-12" v-if="ticket.id_service_type == 3">
            <div class="card payment-card">
              <div class="card-header">Detalles de Entrega</div>
              <div class="grid form p-fluid">
                <div class="col-12">
                  <Dropdown v-model="selectedDeliveryArea" :options="deliveryArea" optionLabel="name" optionValue="id" placeholder="Area de entrega" />
                </div>
              </div>
            </div>
          </div>
           <div v-if="ticket.payments.length > 0" class="col-12">
              <div class="card payment-card">
                   <Chip v-for="payment in ticket.payments" :key="payment.id" 
                    class="custom-chip" @click="editPayment(payment, $event)"
                   :label="payment.name + ' ' + formatCurrency(payment.amount)" :image="'images/tpv/'+ payment.name +'.png'" />
              </div>
          </div>
          <div class="col-12">
              <div class="card payment-card">
                  <div class="grid">
                    <div class="col-12" v-for="paymentType in paymentTypes" :key="paymentType.id">
                        <Button :disabled="pendingAmount <= 0" @click="selectPaymentType(paymentType)" class="google" style="width: 100%">
                          <i>
                            <img style="width: 1.5rem;" :src="'/images/tpv/'+ paymentType.name + '.png'">
                          </i>
                          <span class="px-3">{{paymentType.name}}</span>
                        </Button>                      
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-12">
              <div class="card payment-card">
                  <div class="formgrid grid p-fluid">
                    <div class="col-8">
                      <label for="">Facturacion</label><br>
                      <p style="font-size: smaller;">* Solo se puede pedir factura si la venta tiene un cliente</p>
                    </div>
                    <div class="col-4">
                        <InputSwitch :disabled="ticket.id_customer == null" v-model="need_invoice" />
                    </div> 
                    <div class="col-12"><br>
                        <Button @click="generatePayment" :disabled="ticket.id_service_type == 3 ? pendingAmount > 0 || selectedDeliveryArea == null : pendingAmount > 0" class="p-button-success" icon="pi pi-check" style="width: 100%">PAGAR</Button>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </Sidebar>
</template>

<script>
import InformacionFacturacion from "../pages/TPV/Clientes/InformacionFacturacion.vue";
import { ticket_payment } from "../models/ticket_payment";
import formatCurrency from "../utilities/formatCurrency";
import formMixin from "../mixins/form";
import { errorToast } from "../utilities/MoshaToast";
var math = require("mathjs");

export default {
  mixins: [formMixin],
  components: {InformacionFacturacion},
  props: {
    modelValue: {
      required: true,
    },
    deliveryArea: {
      type: Array,
      default: () => [],
    },
    paymentTypes: {
      type: Array,
      default: () => [],
    },
    ticket: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localValue: this.modelValue,
      selectedPayment: null,
      selectedDeliveryArea: null,
      need_invoice: false,
      modalInvoice: false
    };
  },
  watch: {
    localValue(newValue) {
      this.$emit("update:modelValue", newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
  computed: {
    envio() {
      return this.deliveryArea.find(x => x.id == this.selectedDeliveryArea) ? 
      this.deliveryArea.find(x => x.id == this.selectedDeliveryArea).price : 0; 
    },
    tiempoEntrega() {
      return this.deliveryArea.find(x => x.id == this.selectedDeliveryArea) ? 
      this.deliveryArea.find(x => x.id == this.selectedDeliveryArea).minutes : 0; 
    },
    totalAmount() {
      let total = this.ticket.items.reduce((a, b) => {
          return math.chain(a).add(b.total).done();
      }, 0);
      total = math
      .chain(total)
      .subtract(this.ticket.discount_import)
      .add(this.envio)
      .done();
      return total;
    },
    editingAmount() {
      let paidAmount = this.ticket.payments.reduce((a, b) => {
        let amount = 0
        if (this.selectedPayment) 
          amount = b.id_number != this.selectedPayment.id_number ? b.amount : 0;
        else
          amount = b.amount;  
        return math.chain(a).add(amount).done();
      }, 0);
      return paidAmount ? paidAmount : 0.0;
    },
    paidAmount() {
      let paidAmount = this.ticket.payments.reduce((a, b) => {
          return math.chain(a).add(b.amount).done();
      }, 0);
      return paidAmount ? paidAmount : 0.0;
    },
    pendingAmount() {
      return math.chain(this.totalAmount).subtract(this.paidAmount).done();
    },
    leftoverCash() {
      let leftover = math.chain(this.paidAmount).subtract(this.totalAmount).done();
      return leftover < 0 ? 0 : leftover;
    },
    // canSave() {
    //   let totalEdited = math.chain(this.editingAmount).add(this.selectedPayment ? this.selectedPayment.amount : 0).done();
    //   let difference = math.chain(this.totalAmount).subtract(totalEdited).done();
    //   return difference >= 0;
    // }
  },
  methods: {
    selectAll(event) {
      event.target.select();
    },      
    saveInoviceInfo(info) {
        this.$emit('save', {
          event: 'invoice',
          payload: info
        })
      },
      formatCurrency:formatCurrency,
      onHide() {
          this.$emit('hide')
      },
      generatePayment() {
        try {
          if (this.need_invoice) {
          //* Abrimos dialogo nuevo de facturacion
            if (this.ticket.payments.length > 0){
              const max_amount = this.ticket.payments.reduce((prev, current) => (prev.amount > current.amount) ? prev : current)
              this.showMessage("Para la factura se tomara solamente el tipo de pago " + max_amount.name)
            }
            this.modalInvoice = true;
          }else {
            this.$emit('saveShipping', this.envio);
            this.$emit('saveDelivery', this.selectedDeliveryArea);
            this.$emit('save');
            this.selectedDeliveryArea = null;
          }
        } catch (error) {
          this.showError(error);
        }
      },
      deletePayment(){
        this.$refs.payment.hide();
        this.$emit('delete', this.selectedPayment);
        this.selectedPayment = null;
      },
      async editPayment(payment, event){
          this.selectedPayment = payment;
          this.$refs.payment.toggle(event);
      },
      savePayment(){
        try {
          // if (!this.canSave) 
          //   throw "No se puede modificar por esta cantidad, supera el limite de lo pagado";
          if (this.selectedPayment.amount == 0) 
            throw "No se puede modificar en $0.00"
          
          this.$emit("edit", this.selectedPayment);
          this.selectedPayment = null;
        } catch (error) {
          errorToast(error);
        }
      },
      selectPaymentType(paymentType) {
        let payment = new ticket_payment({
            company: 1,
            branch: 1,
            user: 'dev'
        });
        payment.amount = this.pendingAmount;
        payment.id_pos_payment_type = paymentType.id;
        payment.name = paymentType.name;
        payment.id_ticket = this.ticket.id;
        payment.status = 1;
        this.$emit("pay", payment);
      }
  }
};
</script>


<style>
   .p-chip.custom-chip {
        margin-right: 2rem;
        margin-bottom: .8rem;
        background: steelblue;
        cursor: pointer;
    }
    .p-chip.custom-chip:hover {
        background: whitesmoke;
        cursor: pointer;
    }

  .p-sidebar-content::-webkit-scrollbar {
    width: 5px;
	}

	/* Track */
  .p-sidebar-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
	}

	/* Handle */
  .p-sidebar-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
	}

	/* Handle on hover */
  .p-sidebar-content::-webkit-scrollbar-thumb:hover {
    background: #555;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2)
	}

  .template .p-button.google {
    background: linear-gradient(to left, var(--purple-600) 50%, var(--purple-700) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--purple-700);
  }
  .template .p-button.google:hover {
      background-position: left bottom;
  }
  .template .p-button.google i {
      background-color: var(--purple-700);
  }
  .template .p-button.google:focus {
      box-shadow: 0 0 0 1px var(--purple-400);
  }
</style>