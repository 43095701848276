<template>
  <div class="card">
        <div class="grid fluid" style="width: 100%">
            <div class="col-12">
                <!-- <InputText style="width: 100%" placeholder="Ingrese codigo de barras y presione ↵ ENTER"  @keydown.enter.exact="searchProduct" /> -->
                <Autocomplete ref="product" placeholder="Ingrese codigo de barras o el producto y presione ↵ ENTER" wrapperClass="field col-12 xl:col-12" @enter="searchProduct"  @selected="selectedAutocomplete"  :items="filteredProduct" v-model="myProduct" />
            </div>
        </div>
            <TabView v-if="version == 2" :scrollable="true" v-model:activeIndex="active">
                <TabPanel v-for="family in items" :key="family.label">
                    <template #header>
                        <i :class="family.icon"></i>
                        <span style="margin-left: 10px;">{{family.label}}</span>
                    </template>
                </TabPanel>
            </TabView>
            <div v-else-if="version == 1 && active == null">
                <div class="grid">
                    <div class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4" v-for="family in items" :key="family.label">
                        <!-- <div @click="active = (family.id - 1)" class="card card-family" style="min-height: 5rem;">
                            <div class="content" style="align:center;">
                                <strong>{{family.label}}</strong>
                            </div>
                        </div> -->

                        <BorderButton @click="active = (family.id - 1)" style="min-height: 5rem;" :label="family.label"></BorderButton>
                    </div>
                </div>
            </div>
            <div class="card" v-if="families != []">

                <BorderButton v-if="active != null" label="Regresar" style="font-size: x-small;" @click="active = null"  />
                <h5 style="text-align: center;">{{selectedFamily}}</h5>
                <div class="grid">
                    <ProductButton v-for="product in productsByFamily" @selected="selectedProduct" 
                    :product="product" :key="product.id" />
                </div>
            </div>
    </div>
</template>

<script>


import ProductButton from "../components/ProductButton.vue";
import { ticket_detail } from "../models/ticket_detail";
import { errorToast } from "../utilities/MoshaToast";
import Autocomplete from './general/Autocomplete.vue';
import BorderButton from './general/BorderButton.vue';

var math = require("mathjs");

export default {
    components: { ProductButton, Autocomplete, BorderButton },
    props: {
        version: {
            type: Number,
            default: 1
        },
        families: {
            type: Array,
            default: () => []
        },
        products: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            myProduct: null,
            active: null
        }
    },
    methods: {
        searchCountry(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredProduct = [...this.products];
                } else {
                    this.filteredProduct = this.products.filter((product) => {
                        return product.name.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        selectedAutocomplete(payload) {
            this.myProduct = payload;
            this.searchProduct();
        },
        searchProduct(val) {
            this.myProduct = val ?? this.myProduct;
            let codebar = this.myProduct.indexOf('-') > -1 ? this.myProduct.split('-')[0] : this.myProduct;
            let codebar_product = this.products.find(x => x.codebar == this.myProduct);
            codebar_product = codebar_product ?? this.products.find(x => x.codebar == codebar);
            if (codebar_product) {
                this.selectedProduct(codebar_product);
                this.myProduct = null;
                this.$refs.product.clear();
            }else {
                let name_product = this.products.find(x => x.name == this.myProduct);
                if (name_product) {
                    this.selectedProduct(name_product);
                    this.myProduct = null;
                    this.$refs.product.clear();
                }else {
                    errorToast('No existe el articulo ' + this.myProduct);
                }
            }
        },
        selectedProduct(product) {
            let detail = new ticket_detail();
            detail.promo = product.promo;
            detail.promo_subtype = product.promo_subtype;
            detail.promo_type = product.promo_type;
            detail.unit_price = product.price;
            detail.original_price = product.original_price ?? product.price;
            detail.quantity = 1;
            detail.subtotal = product.price;
            detail.id_product = product.id;
            detail.name = product.name;
            detail.key_name = product.key_name;
            detail.iva_percentage = product.iva_percentage;
            detail.iva_amount = math.chain(detail.subtotal)
                                    .multiply(detail.iva_percentage / 100) 
                                    .done();
            detail.total =  math.chain(detail.subtotal)
                                .add(detail.iva_amount) 
                                .done();
            
            this.$emit("selectedProduct", detail)
        }
    },
    computed: {
        filteredProduct() {
            return this.products
            .map(x => x.name)
            .concat(
                this.products
                    .map(x => (x.codebar ?? 'NOCODE') + '-' + x.name)
            );
        },
        productsByFamily() {
            if (this.families.length > 0) 
                return this.active != null 
                ? this.products.filter(x => x.id_pos_family == this.families[this.active].id)
                : [];
            else
                return [];
        },
        selectedFamily() {
            if (this.families.length > 0) 
                return this.active == null ? "" : this.families[this.active].name;
            else
                return "NOT FOUND";
        },
        items() {
            return this.families.map(x => {
                return {
                    id: x.id,
                    label: x.name,
                    icon: 'pi pi-fw pi-pencil'
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .card-family {
        cursor: pointer;
        background-color: cornflowerblue;
    }
    
    .card-family:hover {
        background-color: rgb(26, 60, 124);
    }
</style>