import axios from "axios";

export class ticket_payment
{
    constructor(session) {
        this.user = session ? session.user : null;
        this.id_company = session ? session.id_company : null;
        this.id_branch = session ? session.id_branch : null;
        this.token = session ? session.token : null;
        this.id = null;
        this.id_number = null;
        this.id_ticket = null;
        this.id_pos_payment_type = null;
        this.name = null;
        this.amount = null;
        this.status = null;
        this.reference = null;
        this.created_by = null;
        this.created_terminal = null;
        this.created = null;
        this.modified_by = null;
        this.modified_machine = null;
        this.modified = null;
    }

    async register() {
        let response = await axios.post("tpv/ticket/register", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }
}