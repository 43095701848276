<template>
  <div class="card">
    <Dialog header="Mesas" v-model:visible="modalMesa" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
        <div class="grid">
          <div v-for="table in tables" :key="table.id" class="col-4">
            <div @click="selectTable(table)" v-bind:class="{ card: true, order : table.name != tableName, selected_order : table.name == tableName }" >
                <div class="card-header"> 
                    <h5>{{table.name}}</h5>
                </div>
                <div>
                  <img style="max-width: 100%; max-height: 100%;" src="/images/tpv/MESA.png" />
                </div>
            </div>
          </div>
        </div>
    </Dialog>
    <Dialog header="Mesero" v-model:visible="modalMesero" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
        <div class="grid">
          <div v-for="waiter in waiters" :key="waiter.id" class="col-4">
            <div @click="selectWaiter(waiter)" v-bind:class="{ card: true, order : waiter.name != waiterName, selected_order : waiter.name == waiterName }" >
                <div class="card-header"> 
                    <h5>{{waiter.name}}</h5>
                </div>
                <div>
                  <img style="display: block; margin-left: auto;margin-right: auto;max-width: 80%;max-height: 80%;" src="/images/tpv/waiter.png" />
                </div>
            </div>
          </div>
        </div>
    </Dialog>
    <Dialog header="Clientes" v-model:visible="modalCustomerUI" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '75vw'}" :modal="true">
        <Cliente :modal="true" @save="savedCustomer" />
    </Dialog>
    <Dialog header="Clientes" v-model:visible="modalCustomer" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
        <div class="grid">
          <div class="col-12">
            <BasicDatatable :gridlines="true" :dataKey="'id'" :selectionModeColumn="'single'" :headers="headers" :rows="customers" :exportable="false" @selected="selectCustomer" />
          </div>
        </div>
    </Dialog>
    <h5 style="text-align: center">Tipo de Venta</h5>
    <div class="formgrid grid p-fluid">
      <div class="field col-6">
        <Dropdown 
          v-model="localValue" 
          :options="saleTypes" 
          optionLabel="name"
          optionValue="id"
          placeholder="..."
        >
          <template #value="slotProps">
            <div v-if="slotProps.value">
                <img style="width: 2rem;" :src="'/images/tpv/' + saleTypes.find(x => x.id == slotProps.value).name + '.png'" />
                <span style="padding-left: 1rem; padding-bottom: .5rem;">{{ saleTypes.find(x => x.id == slotProps.value).name }}</span>
            </div>
            <span v-else>
                {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div>
                <img style="width: 2rem;" :src="'/images/tpv/'+slotProps.option.name+'.png'" />
                <span style="padding-left: 1rem; padding-bottom: .5rem;">{{ slotProps.option.name }}</span>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="field col-6">
        <Dropdown @change="changePrice" :options="myPrices" v-model="myPriceList" optionLabel="name" optionValue="id">
        </Dropdown>
      </div>
       <div v-if="localValue == 1" class="col-6">
           <div class="p-inputgroup">
              <InputText :value="tableName" class="input-pointer" @click="modalMesa = true" :readonly="true" placeholder="Mesa"/>
              <Button icon="pi pi-trash" class="p-button-warning" @click="clearTable" />
          </div>
        </div>
        <div v-if="localValue == 1" class="col-6">
           <div class="p-inputgroup">
              <InputText :value="meseroName" class="input-pointer" @click="modalMesero = true" :readonly="true" placeholder="Mesero"/>
              <Button icon="pi pi-trash" class="p-button-warning" @click="clearTable" />
          </div>
        </div>
        <div v-if="localValue == 3" class="col-12">
           <Button label="Informacion de entrega" @click="deliveryClicked"></Button>
        </div>
        <Divider />
        <div class="col-12">
          
          <div class="p-inputgroup">
              <InputText :value="customer.name" class="input-pointer" @click="modalCustomer = true" :readonly="true" placeholder="Seleccione un cliente..."/>
              <Button icon="pi pi-trash" class="p-button-warning" @click="clearCustomer" />
              <Button icon="pi pi-plus" class="p-button-info" @click="modalCustomerUI = true" />
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import BasicDatatable from "../components/general/BasicDatatable.vue";
import Cliente from "../pages/TPV/Clientes/Cliente.vue";
import { HeaderGrid } from '../utilities/General';

export default {
  expose: ['fillCustomer'],
  components: {BasicDatatable, Cliente},
  emits: ['change-price'],
  props: {
    price_lists: {
      type: Array,
      default: () => []
    },
    table: {
      type: Object,
      default: null
    },
    waiter: {
      type: Object,
      default: null
    },
    modelValue: {
      required: true,
    },
    price_list: {
      type: Number,
      default: 0
    },
    services: {
      type: Object, 
      default: () => {}
    },
    customers:
    {
      type: Array,
      default: () => []
    },
    tables: {
      type: Array,
      default: () => []
    },
    waiters: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    myPrices() {
      let price_lists = [{id: 0, name: 'NORMAL'}]; 
      this.price_lists.forEach(x => price_lists.push(x));
      return price_lists;
    },
    tableName() { 
      return this.table == null ? '' : this.table.name;
    },
    meseroName() { 
      return this.waiter == null ? '' : this.waiter.name;
    }
  },
  data() {
    return {
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("E-mail", "email"),
        new HeaderGrid("Telefono", "phone"),
      ],
      modalMesa: false,
      modalMesero: false,
      modalCustomer: false,     
      modalCustomerUI: false,
      localValue: this.modelValue,
      myPriceList: this.price_list,
      customer: {
        id: null,
        name: null
      },
      saleTypes: [
        { id: 1, name: "MESA" },
        { id: 2, name: "MOSTRADOR" },
        { id: 3, name: "DOMICILIO" },
      ],
    };
  },
  methods: {
    deliveryClicked() {
      this.$emit('open-delivery');
    },
    changePrice(payload) {
      this.$emit('change-price', payload.value)
    },
    savedCustomer(customer) { 
      this.modalCustomerUI = false;
      this.customer.name = customer.name;
      this.customer.id = customer.id;
      this.$emit("new-customer", customer)
      this.$emit('on-customer-selected', customer)
    },
    clearTable() {
      this.$emit('on-table-selected', null)
    },
    clearCustomer() {
      this.customer.name = null;
      this.customer.id = null;
      this.$emit('on-customer-selected', {
        id: null
      })
    },
    fillCustomer(id){
      let customer = this.customers.find(x => x.id == id);
      this.customer.id = customer ? id : null;
      this.customer.name = customer ? customer.name : null;
    },
    selectCustomer(customer) {
      this.modalCustomer = false;
      this.customer.name = customer.name;
      this.customer.id = customer.id;
      this.$emit('on-customer-selected', customer)
    },
    selectWaiter(waiter) {
      this.$emit('on-waiter-selected', waiter)
      this.modalMesero = false;
    },
    selectTable(table) {
      this.$emit('on-table-selected', table)
      this.modalMesa = false;
    }
  },
  watch: {
    services(newValue) { 
      let saleTypes = [];
      if (newValue.is_table_enable) {
        saleTypes.push({ id: 1, name: "MESA" });
      }
      if (newValue.is_counter_enable) {
        saleTypes.push({ id: 2, name: "MOSTRADOR" });
      }
      if (newValue.is_shipping_enable) {
        saleTypes.push({ id: 3, name: "DOMICILIO" });
      }
      this.localValue = saleTypes[0].id;
      this.saleTypes = saleTypes;
    },
    localValue(newValue) {
      this.$emit("update:modelValue", newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style>
  .input-pointer {
    cursor: pointer;
  }

      .card-header {
        text-align: center;
        padding-bottom: .5rem;
    }

    .order {
        background-color:cornsilk;
        color:darkslategrey;
        height: 100%;
        cursor: pointer;
    }

    .order:hover {
        background-color:darkgrey;
        color:darkslategrey;
        
    }

    .selected_order {
        background-color: #6dbac0;
        color:darkslategrey;
        height: 100%;
        cursor: pointer;
    }

</style>