<template>
    <Dialog
        v-model:visible="localValue"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }" 
        :style="{ width: '50vw' }"         
        :modal="true" 
        :closable="true"
      >
      <Loader v-model="loading"></Loader>
      <template #header>
        <h4>Buscar ticket</h4>
      </template>
      <div class="confirmation-content">
        <div class="p-fluid formgrid grid">
          <FormCalendar v-model="entity.date" :valid="validate.validations.date" wrapperClass="col-6 field" :label="'Fecha'"></FormCalendar>
          <FormInputText v-model="entity.number" :valid="validate.validations.number" wrapperClass="col-6 field" :label="'Folio'"></FormInputText>
        </div>
      </div>
      <template #footer>
        <div class="p-fluid formgrid grid">
          <div class="col-12">
            <Button
              label="Buscar"
              icon="pi pi-search"
              @click="search"
            />
          </div>
        </div>
      </template>
    </Dialog>
  </template>
  
  <script>
    import FormInputText from "../components/general/FormInputText.vue";
    import FormCalendar from "../components/general/FormCalendar.vue";
    import Loader from "../components/general/Loader.vue";

    import formMixin from "../mixins/form";
  import { Rule, validate } from '../utilities/General';
  import { ticket } from "../models/ticket";
  export default {
    mixins: [formMixin],
    props: {
      modelValue: {
        required: true,
      },
      message: {
        type: String,
        default: '¿Estas seguro de eliminar el registro?'
      }
    },
    components: {
      FormCalendar, FormInputText, Loader
    },
    data() {
      return {
        localValue: this.modelValue,
        loading: false,
        entity: {
          date: new Date(),
          number: null
        },
        rules: [
          new Rule({ name: 'date' }),
          new Rule({ name: 'number' }),
        ],
        validate: {
                valid: false,
                validations: {
                    date: null,
                    number: null
                },
            },
      };
    },
    methods: {
      async search() {
        try {
          this.validate = validate(this.entity, this.rules);
          if (!this.validate.valid)
            throw "Favor de verificar los campos"
          this.loading = true;
          let my_ticket = await new ticket(this.session).search(this.entity.date, this.entity.number, "true");
          if (my_ticket.status == 9) {
            throw "Este ticket ya esta cancelado"
          }
          this.localValue = false;
          this.$emit("found", my_ticket);
        } catch (error) {
          this.showError(error);
        } finally { 
          this.loading = false;
        }
      }
    },
    watch: {
      localValue(newValue) {
        this.$emit('update:modelValue', newValue);
      },
      modelValue(newValue) {
        this.localValue = newValue;
      },
    },
  };
  </script>
  
  <style></style>
  