<template>
  <div class="card tickets">
      <div class="formgrid grid p-fluid">
          <div v-for="ticket in visibleTickets" :key="ticket.id" class="col-6 md:col-4 xl:col-3">
            <div @click="selectTicket(ticket)" class="card order">
                <div class="card-header"> 
                    <h5>#{{ticket.order_number}}</h5>
                </div>
                <div class="card-content"> 
                    <div>{{formatDate(ticket.date, 'date')}}</div>
                    <div>{{formatDate(ticket.date, 'time')}}</div>
                    <div>{{formatCurrency(ticket.total - (ticket.discount_import ?? 0.00))}}</div>
                </div>
            </div>
          </div>
      </div>
      <div class="card-footer">
          <Paginator v-model:first="first" :rows="adders + 1" :totalRecords="tickets.length"></Paginator>
      </div>
  </div>
</template>

<script>
import formatCurrency from "../utilities/formatCurrency";
import formatDate from "../utilities/formatDate";
export default {
    props: {
        tickets: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            first: 0
        }
    },
    computed: { 
        adders() {
            if (this.$windowWidth > 800 & this.$windowWidth < 1200)
                return 2;
            if (this.$windowWidth < 800) 
                return 1;
            else 
                return 3;
        },
        visibleTickets() {
            return this.tickets.filter((ticket, index) => {
                return index >= this.first && index <= this.first + this.adders;
            });
        }
    },
    watch: {
        adders() {
           this.first = 0;
        }
    },
    methods: {
        formatDate: formatDate,
        formatCurrency: formatCurrency,
        selectTicket(ticket) {
            this.$emit("select", ticket);
        }
    }
}
</script>

<style>
    /* .tickets {
        max-height: 14rem;
    }
 */

    .card-header {
        text-align: center;
        padding-bottom: .5rem;
    }

    .order {
        background-color:cornsilk;
        color:darkslategrey;
        height: 100%;
        cursor: pointer;
    }

    .order:hover {
        background-color:darkgrey;
        color:darkslategrey;
        
    }
</style>