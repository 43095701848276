import moment from "moment";

export default function (value, type) {
  if (type == "date") {
      return moment(value).format("DD/MM/YYYY");
  }else if(type == "time"){
      return moment(value).format("HH:mm:ss A");
  }else {
      return "N/D";
  }
}
